import { createApp } from 'vue';
import App from './App.vue';
import 'normalize.css';
import './assets/css/global.css';
import router from './router';
import VueGtag from 'vue-gtag';
import { MotionPlugin } from '@vueuse/motion';
import { createHead } from "@vueuse/head"

const app = createApp(App);
const head = createHead();

app.use(router);
app.use(head);
app.use(MotionPlugin);

app.use(
  VueGtag,
  {
    appName: 'HelixPay.ph',
    pageTrackerScreenviewEnabled: true,
    config: {
      id: process.env.GOOGLE_ANALYTICS_ID,
    },
  },
  router
);

app.mount('#app');

window.snapSaveState = () => {
  document.querySelector("#app").setAttribute("data-server-rendered", "true");
};
