<template>
  <div class="announcement">
    Want a world-class ticketing solution?<br />
    We're here in Manila, Call Us: 63288417125
  </div>
</template>

<style scoped>
.announcement {
  background: #000;
  color: #fff;
  padding: 12px 16px;
  font-size: 11px;
  text-align: center;
  border-bottom: thin solid #666;
}

@media (min-width: 600px) {
  .announcement br {
    display: none;
  }

  .announcement {
    font-size: 14px;
  }
}
</style>
